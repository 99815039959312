import { useMemo, useState, useEffect } from 'react'
import useSWR from 'swr'
// import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client'
import { maxBy, minBy } from 'lodash'
// import fetch from 'cross-fetch';
// import * as ethers from 'ethers'

// import { getAddress, ARBITRUM, AVALANCHE } from './addresses'

// import RewardReader from '../abis/RewardReader.json'
// import FlpManager from '../abis/FlpManager.json'
// import Token from '../abis/v1/Token.json'

// const { JsonRpcProvider } = ethers.providers

import { ALGORAND_LEDGER, API_URL } from './config'

// const providers = {
//   arbitrum: new JsonRpcProvider('https://arb1.arbitrum.io/rpc'),
//   avalanche: new JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc')
// }

// function getProvider(chainName) {
//   if (!(chainName in providers)) {
//     throw new Error(`Unknown chain ${chainName}`)
//   }
//   return providers[chainName]
// }

// function getChainId(chainName) {
//   const chainId = {
//     arbitrum: ARBITRUM,
//     avalanche: AVALANCHE
//   }[chainName]
//   if (!chainId) {
//     throw new Error(`Unknown chain ${chainName}`)
//   }
//   return chainId
// }

const NOW_TS = parseInt(Date.now() / 1000)
const FIRST_DATE_TS = parseInt(+(new Date(2022, 8, 6)) / 1000) - new Date().getTimezoneOffset() * 60;

// function fillNa(arr) {
//   const prevValues = {}
//   let keys
//   if (arr.length > 0) {
//     keys = Object.keys(arr[0])
//     delete keys.timestamp
//     delete keys.id
//   }

//   for (const el of arr) {
//     for (const key of keys) {
//       if (!el[key]) {
//         if (prevValues[key]) {
//           el[key] = prevValues[key]
//         }
//       } else {
//         prevValues[key] = el[key]
//       }
//     }
//   }
//   return arr
// }

// export async function queryEarnData(chainName, account) {
//   const provider = getProvider(chainName)
//   const chainId = getChainId(chainName)
//   const rewardReader = new ethers.Contract(getAddress(chainId, 'RewardReader'), RewardReader.abi, provider)
//   const flpContract = new ethers.Contract(getAddress(chainId, 'FLP'), Token.abi, provider)
//   const flpManager = new ethers.Contract(getAddress(chainId, 'FlpManager'), FlpManager.abi, provider)

//   let depositTokens
//   let rewardTrackersForDepositBalances
//   let rewardTrackersForStakingInfo

//   if (chainId === ARBITRUM) {
//     depositTokens = ['0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a', '0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0x35247165119B69A40edD5304969560D0ef486921', '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258']
//     rewardTrackersForDepositBalances = ['0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F', '0x4e971a87900b931fF39d1Aad67697F49835400b6']
//     rewardTrackersForStakingInfo = ['0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F', '0x1aDDD80E6039594eE970E5872D247bf0414C8903', '0x4e971a87900b931fF39d1Aad67697F49835400b6']
//   } else {
//     depositTokens = ['0x62edc0692BD897D2295872a9FFCac5425011c661', '0xFf1489227BbAAC61a9209A08929E4c2a526DdD17', '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2', '0x01234181085565ed162a948b6a5e88758CD7c7b8']
//     rewardTrackersForDepositBalances = ['0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F']
//     rewardTrackersForStakingInfo = ['0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0x9e295B5B976a184B14aD8cd72413aD846C299660', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F']
//   }

//   const [
//     balances,
//     stakingInfo,
//     flpTotalSupply,
//     flpAum,
//     gmxPrice
//   ] = await Promise.all([
//     rewardReader.getDepositBalances(account, depositTokens, rewardTrackersForDepositBalances),
//     rewardReader.getStakingInfo(account, rewardTrackersForStakingInfo).then(info => {
//       return rewardTrackersForStakingInfo.map((_, i) => {
//         return info.slice(i * 5, (i + 1) * 5)
//       })
//     }),
//     flpContract.totalSupply(),
//     flpManager.getAumInUsdg(true),
//     fetch('https://api.coingecko.com/api/v3/simple/price?ids=gmx&vs_currencies=usd').then(async res => {
//       const j = await res.json()
//       return j['gmx']['usd']
//     })
//   ])

//   const flpPrice = (flpAum / 1e18) / (flpTotalSupply / 1e18)
//   const now = new Date()

//   return {
//     FLP: {
//       stakedFLP: balances[5] / 1e18,
//       pendingETH: stakingInfo[4][0] / 1e18,
//       pendingEsGMX: stakingInfo[3][0] / 1e18,
//       flpPrice
//     },
//     GMX: {
//       stakedGMX: balances[0] / 1e18,
//       stakedEsGMX: balances[1] / 1e18,
//       pendingETH: stakingInfo[2][0] / 1e18,
//       pendingEsGMX: stakingInfo[0][0] / 1e18,
//       gmxPrice
//     },
//     timestamp: parseInt(now / 1000),
//     datetime: now.toISOString()
//   }
// }

// export const tokenDecimals = {
//   "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": 18, // WETH
//   "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f": 8, // BTC
//   "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": 6, // USDC
//   "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0": 18, // UNI
//   "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9": 6, // USDT
//   "0xf97f4df75117a78c1a5a0dbb814af92458539fb4": 18, // LINK
//   "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a": 18, // MIM
//   "0x17fc002b466eec40dae837fc4be5c67993ddbd6f": 18, // FRAX
//   "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": 18, // DAI
// }

// export const tokenSymbols = {
//   // Arbitrum
//   '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': 'BTC',
//   '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': 'ETH',
//   '0xf97f4df75117a78c1a5a0dbb814af92458539fb4': 'LINK',
//   '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0': 'UNI',
//   '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 'USDC',
//   '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9': 'USDT',
//   '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a': 'MIM',
//   '0x17fc002b466eec40dae837fc4be5c67993ddbd6f': 'FRAX',
//   '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': 'DAI',

//   // Avalanche
//   '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7': 'AVAX',
//   '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab': 'WETH.e',
//   '0x50b7545627a5162f82a992c33b87adc75187b218': 'WBTC.e',
//   '0x130966628846bfd36ff31a822705796e8cb8c18d': 'MIM',
//   '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664': 'USDC.e',
//   '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e': 'USDC'
// }

// const knownSwapSources = {
//   arbitrum: {
//     '0xabbc5f99639c9b6bcb58544ddf04efa6802f4064': 'GMX', // Router
//     '0x09f77e8a13de9a35a7231028187e9fd5db8a2acb': 'GMX', // Orderbook
//     '0x98a00666cfcb2ba5a405415c2bf6547c63bf5491': 'GMX', // PositionManager old
//     '0x87a4088bd721f83b6c2e5102e2fa47022cb1c831': 'GMX', // PositionManager
//     '0x7257ac5d0a0aac04aa7ba2ac0a6eb742e332c3fb': 'GMX', // OrderExecutor
//     '0x1a0ad27350cccd6f7f168e052100b4960efdb774': 'GMX', // FastPriceFeed
//     '0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237': 'Dodo',
//     '0x11111112542d85b3ef69ae05771c2dccff4faa26': '1inch',
//     '0x6352a56caadc4f1e25cd6c75970fa768a3304e64': 'OpenOcean', // OpenOceanExchangeProxy
//     '0x4775af8fef4809fe10bf05867d2b038a4b5b2146': 'Gelato',
//     '0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1': 'LiFiDiamond',
//     '0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a': 'Vovo', // Vovo BTC UP USDC (vbuUSDC)
//     '0xc4bed5eeeccbe84780c44c5472e800d3a5053454': 'Vovo', // Vovo ETH UP USDC (veuUSDC)
//     '0xe40beb54ba00838abe076f6448b27528dd45e4f0': 'Vovo', // Vovo BTC UP USDC (vbuUSDC)
//     '0x9ba57a1d3f6c61ff500f598f16b97007eb02e346': 'Vovo', // Vovo ETH UP USDC (veuUSDC)
//     '0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f': 'JonesDAO',
//     '0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059': 'WardenSwap',
//     '0x1111111254fb6c44bac0bed2854e76f90643097d': '1inch',
//     '0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d': 'deBridge',
//     '0xc30141b657f4216252dc59af2e7cdb9d8792e1b0': 'socket.tech'
//   },
//   avalanche: {
//     '0x4296e307f108b2f583ff2f7b7270ee7831574ae5': 'GMX',
//     '0x5f719c2f1095f7b9fc68a68e35b51194f4b6abe8': 'GMX',
//     '0x7d9d108445f7e59a67da7c16a2ceb08c85b76a35': 'GMX', // FastPriceFeed
//     '0xf2ec2e52c3b5f8b8bd5a3f93945d05628a233216': 'GMX', // PositionManager
//     '0xc4729e56b831d74bbc18797e0e17a295fa77488c': 'Yak',
//     '0x409e377a7affb1fd3369cfc24880ad58895d1dd9': 'Dodo',
//     '0x6352a56caadc4f1e25cd6c75970fa768a3304e64': 'OpenOcean',
//     '0x7c5c4af1618220c090a6863175de47afb20fa9df': 'Gelato',
//     '0x1111111254fb6c44bac0bed2854e76f90643097d': '1inch',
//     '0xdef171fe48cf0115b1d80b88dc8eab59176fee57': 'ParaSwap',
//     '0x2ecf2a2e74b19aab2a62312167aff4b78e93b6c5': 'ParaSwap',
//   }
// }

const defaultFetcher = url => fetch(url).then(res => res.json())
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
        const data = await fetcher(url)
        setData(data)
      } catch (ex) {
        console.error(ex)
        setError(ex)
      }
      setLoading(false)
    }

    fetchData()
  }, [fetcher, url])

  return [data, loading, error]
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    ALGO: 'algorand',
    XSOL: 'solana',
  }[symbol]

  const now = Date.now() / 1000
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`

  const [res, loading, error] = useRequest(url)

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null
    }

    const ret = res.prices.map(item => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for FLP we store last price of the day
      const timestamp = item[0] - 1
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400
      return {
        timestamp: groupTs,
        value: item[1]
      }
    })
    return ret
  }, [res])

  return [data, loading, error]
}

function getImpermanentLoss(change) {
  return 2 * Math.sqrt(change) / (1 + change) - 1
}

// function getChainSubgraph(chainName) {
//   return chainName === "arbitrum" ? "gmx-io/gmx-stats" : "gmx-io/gmx-avalanche-stats"
// }

// export function useGraph(querySource, { subgraph = null, subgraphUrl = null, chainName = "arbitrum" } = {}) {
//   const query = gql(querySource)

//   if (!subgraphUrl) {
//     if (!subgraph) {
//       subgraph = getChainSubgraph(chainName)
//     }
//     subgraphUrl = `https://api.thegraph.com/subgraphs/name/${subgraph}`;
//   }

//   const client = new ApolloClient({
//     link: new HttpLink({ uri: subgraphUrl, fetch }),
//     cache: new InMemoryCache()
//   })
//   const [data, setData] = useState()
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState(null)

//   useEffect(() => {
//     setLoading(true)
//   }, [querySource, setLoading])

//   useEffect(() => {
//     client.query({query}).then(res => {
//       setData(res.data)
//       setLoading(false)
//     }).catch(ex => {
//       console.warn('Subgraph request failed error: %s subgraphUrl: %s', ex.message, subgraphUrl)
//       setError(ex)
//       setLoading(false)
//     })
//   }, [client, query, querySource, setData, setError, setLoading, subgraphUrl])

//   return [data, loading, error]
// }

export function useLastRound() {
  const {data, error} = useSWR(`${API_URL}/v2/transactions/params`, fetcher)
  
  const loading = !data && !error

  let lastRound = null
  if (data && data['last-round']) {
    lastRound = data['last-round']
  }

  return [lastRound, loading, error]
}

// export function useLastSubgraphBlock(chainName = "arbitrum") {
//   const [data, loading, error] = useGraph(`{
//     _meta {
//       block {
//         number
//       }
//     }
//   }`, { chainName })
//   const [block, setBlock] = useState(null)

//   useEffect(() => {
//     if (!data) {
//       return
//     }

//     providers[chainName].getBlock(data._meta.block.number).then(block => {
//       setBlock(block)
//     })
//   }, [data, setBlock])

//   return [block, loading, error]
// }

export function useTradersData({ from = FIRST_DATE_TS, to = NOW_TS } = {}) {
  const { data: pnlData, error: pnlError } = useSWR(getApiUrl(`/daily_pnl/?timestamp__gte=${from}&timestamp__lt=${to}`), fetcher)

  const { data: openInterestsData, error: openInterestsError } = useSWR(getApiUrl(`/open_interests/?timestamp__gte=${from}&timestamp__lt=${to}`), fetcher)

  const loading = (!pnlData && !pnlError) || (!openInterestsData && !openInterestsError)

  let ret = null
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;

  const data = openInterestsData && pnlData ? openInterestsData.map((dataItem, index) => {
    const longOpenInterest = dataItem.long / 1e6
    const shortOpenInterest = dataItem.short / 1e6
    const openInterest = longOpenInterest + shortOpenInterest

    const pnlItem = pnlData[index] ? pnlData[index] : { profit: 0, loss: 0, total_profit: 0, total_loss: 0}
    const profit = pnlItem.profit / 1e6
    const loss = pnlItem.loss / 1e6
    const profitCumulative = pnlItem.total_profit / 1e6
    const lossCumulative = pnlItem.total_loss / 1e6
    const pnlCumulative = profitCumulative - lossCumulative
    const pnl = profit - loss
    currentProfitCumulative += profit
    currentLossCumulative -= loss
    currentPnlCumulative += pnl
    return {
      longOpenInterest,
      shortOpenInterest,
      openInterest,
      profit,
      loss: -loss,
      profitCumulative,
      lossCumulative: -lossCumulative,
      pnl,
      pnlCumulative,
      timestamp: dataItem.timestamp,
      currentPnlCumulative,
      currentLossCumulative,
      currentProfitCumulative
    }
  }) : null

  if (data && data.length > 0) {
    const maxProfit = maxBy(data, item => item.profit).profit
    const maxLoss = minBy(data, item => item.loss).loss
    const maxProfitLoss = Math.max(maxProfit, -maxLoss)

    const maxPnl = maxBy(data, item => item.pnl).pnl
    const minPnl = minBy(data, item => item.pnl).pnl
    const maxCurrentCumulativePnl = maxBy(data, item => item.currentPnlCumulative).currentPnlCumulative
    const minCurrentCumulativePnl = minBy(data, item => item.currentPnlCumulative).currentPnlCumulative

    const currentProfitCumulative = data[data.length - 1].currentProfitCumulative
    const currentLossCumulative = data[data.length - 1].currentLossCumulative
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(
        Math.abs(maxPnl),
        Math.abs(minPnl),
      ),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
      
    }

    ret = {
      data,
      stats
    }
  }

  return [ret, loading]
}

// function getSwapSourcesFragment(skip = 0, from, to) {
//   return `
//     hourlyVolumeBySources(
//       first: 1000
//       skip: ${skip}
//       orderBy: timestamp
//       orderDirection: desc
//       where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
//       subgraphError: allow
//     ) {
//       timestamp
//       source
//       swap
//     }
//   `
// }
// export function useSwapSources({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "arbitrum" } = {}) {
//   const query = `{
//     a: ${getSwapSourcesFragment(0, from, to)}
//     b: ${getSwapSourcesFragment(1000, from, to)}
//     c: ${getSwapSourcesFragment(2000, from, to)}
//     d: ${getSwapSourcesFragment(3000, from, to)}
//     e: ${getSwapSourcesFragment(4000, from, to)}
//   }`
//   const [graphData, loading, error] = useGraph(query, { chainName })

//   let data = useMemo(() => {
//     if (!graphData) {
//       return null
//     }

//     const {a, b, c, d, e} = graphData
//     const all = [...a, ...b, ...c, ...d, ...e]

//     const totalVolumeBySource = a.reduce((acc, item) => {
//       const source = knownSwapSources[chainName][item.source] || item.source
//       if (!acc[source]) {
//         acc[source] = 0
//       }
//       acc[source] += item.swap / 1e30
//       return acc
//     }, {})
//     const topVolumeSources = new Set(
//       Object.entries(totalVolumeBySource).sort((a, b) => b[1] - a[1]).map(item => item[0]).slice(0, 30)
//     )

//     let ret = chain(all)
//       .groupBy(item => parseInt(item.timestamp / 86400) * 86400)
//       .map((values, timestamp) => {
//         let all = 0
//         const retItem = {
//           timestamp: Number(timestamp),
//           ...values.reduce((memo, item) => {
//             let source = knownSwapSources[chainName][item.source] || item.source
//             if (!topVolumeSources.has(source)) {
//               source = 'Other'
//             }
//             if (item.swap != 0) {
//               const volume = item.swap / 1e30
//               memo[source] = memo[source] || 0
//               memo[source] += volume
//               all += volume
//             }
//             return memo
//           }, {})
//         }

//         retItem.all = all

//         return retItem
//       })
//       .sortBy(item => item.timestamp)
//       .value()

//     return ret
//   }, [graphData])

//   return [data, loading, error]
// }

export function useTotalVolumeFromServer() {
  const { data, error } = useSWR(getApiUrl(`/volumes/daily_and_total/`), fetcher)

  const loading = !data && !error

  return [
    !data ? null : (data.totalVolume / 1e6 || 0),
    loading
  ]
}

export function getApiUrl(path) {
  const serverUrl = ALGORAND_LEDGER === "MainNet"
    ? "https://api.mainnet.fxdx.exchange/api"
    : "https://api.algo.fxdx.exchange/api"

  return `${serverUrl}${path}`
}

export default async function fetcher(...args) {
  const res = await fetch(...args)
  return res.json()
}

export function useVolumeDataFromServer({ from = FIRST_DATE_TS, to = NOW_TS } = {}) {
  const { data, error } = useSWR(getApiUrl(`/volume_stats/?timestamp__gte=${from}&timestamp__lt=${to}`), fetcher)

  const loading = !data && !error

  const ret = useMemo(() => {
    if (!data) {
      return null
    }

    let cumulative = 0
    const cumulativeByTs = {}
    return data.map(item => {
      const timestamp = item.timestamp
      const all = item.daily_volume / 1e6

      let movingAverageAll
      const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      cumulative += all
      cumulativeByTs[timestamp] = cumulative
      return {
        timestamp,
        all,
        cumulative,
        movingAverageAll,
        margin: item.margin_trading / 1e6,
        liquidation: item.liquidation / 1e6,
        swap: item.swap / 1e6,
        mint: item.mint_flp / 1e6,
        burn: item.burn_flp / 1e6,
      }
    })
  }, [data])

  return [ret, loading]
}

export function useUsersData({ from = FIRST_DATE_TS, to = NOW_TS } = {}) {
  const { data: dbData, error } = useSWR(getApiUrl(`/user_stats/?timestamp__gte=${from}&timestamp__lt=${to}`), fetcher)

  const loading = !dbData && !error

  const prevUniqueCountCumulative = {}
  let cumulativeNewUserCount = 0;

  const keyMap = {
    'uniqueCount': 'unique_count',
    'uniqueSwapCount': 'unique_swap_count',
    'uniqueMintBurnCount': 'unique_mint_burn_count',
    'uniqueMarginCount': 'unique_margin_count',
    'uniqueCountCumulative': 'unique_total',
    'uniqueSwapCountCumulative': 'unique_swap_total',
    'uniqueMintBurnCountCumulative': 'unique_mint_burn_total',
    'uniqueMarginCountCumulative': 'unique_margin_total',
    'actionCount': 'action_count',
    'actionSwapCount': 'action_swap_count',
    'actionMarginCount': 'action_margin_count',
    'actionMintBurnCount': 'action_mint_burn_count',
    'timestamp': 'timestamp'
  }

  const data = dbData ? dbData.map(dbItem => {
    const item = {}

    for (let key in keyMap) {
      item[key] = dbItem[keyMap[key]]
    }

    const newCountData = ['', 'Swap', 'Margin', 'MintBurn'].reduce((memo, type) => {
      memo[`new${type}Count`] = prevUniqueCountCumulative[type]
        ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
        : item[`unique${type}Count`]
      prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`]
      return memo
    }, {})

    cumulativeNewUserCount += newCountData.newCount;
    const oldCount = item.uniqueCount - newCountData.newCount
    const oldPercent = (oldCount / item.uniqueCount * 100).toFixed(1)
    return {
      all: item.uniqueCount,
      uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
      oldCount,
      oldPercent,
      cumulativeNewUserCount,
      ...newCountData,
      ...item
    }
  }) : null

  return [data, loading, error]
}

// export function useFundingRateData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "arbitrum" } = {}) {
//   const query = `{
//     fundingRates(
//       first: 1000,
//       orderBy: timestamp,
//       orderDirection: desc,
//       where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
//       subgraphError: allow
//     ) {
//       id,
//       token,
//       timestamp,
//       startFundingRate,
//       startTimestamp,
//       endFundingRate,
//       endTimestamp
//     }
//   }`
//   const [graphData, loading, error] = useGraph(query, { chainName })


//   const data = useMemo(() => {
//     if (!graphData) {
//       return null
//     }

//     const groups = graphData.fundingRates.reduce((memo, item) => {
//       const symbol = tokenSymbols[item.token]
//       if (symbol === 'MIM') {
//         return memo
//       }
//       memo[item.timestamp] = memo[item.timestamp] || {
//         timestamp: item.timestamp
//       }
//       const group = memo[item.timestamp]
//       const timeDelta = parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600

//       let fundingRate = 0
//       if (item.endFundingRate && item.startFundingRate) {
//         const fundingDelta = item.endFundingRate - item.startFundingRate
//         const divisor = timeDelta / 86400
//         fundingRate = fundingDelta / divisor / 10000 * 365
//       }
//       group[symbol] = fundingRate
//       return memo
//     }, {})
    
//     return fillNa(sortBy(Object.values(groups), 'timestamp'))
//   }, [graphData])

//   return [data, loading, error]
// }

const MOVING_AVERAGE_DAYS = 7
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS

// export function useVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "arbitrum" } = {}) {
// 	const PROPS = 'margin liquidation swap mint burn'.split(' ')
//   const timestampProp = chainName === "arbitrum" ? "id" : "timestamp"
//   const query = `{
//     volumeStats(
//       first: 1000,
//       orderBy: ${timestampProp},
//       orderDirection: desc
//       where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
//       subgraphError: allow
//     ) {
//       ${timestampProp}
//       ${PROPS.join('\n')}
//     }
//   }`
//   const [graphData, loading, error] = useGraph(query, { chainName })

//   const data = useMemo(() => {
//     if (!graphData) {
//       return null
//     }

//     let ret =  sortBy(graphData.volumeStats, timestampProp).map(item => {
//       const ret = { timestamp: item[timestampProp] };
//       let all = 0;
//       PROPS.forEach(prop => {
//         ret[prop] = item[prop] / 1e30
//         all += ret[prop]
//       })
//       ret.all = all
//       return ret
//     })

//     let cumulative = 0
//     const cumulativeByTs = {}
//     return ret.map(item => {
//       cumulative += item.all

//       let movingAverageAll
//       const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD
//       if (movingAverageTs in cumulativeByTs) {
//         movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
//       }

//       return {
//         movingAverageAll,
//         cumulative,
//         ...item
//       }
//     })
//   }, [graphData])

//   return [data, loading, error]
// }

export function useTotalFees() {
  const { data, error } = useSWR(getApiUrl(`/fees/total/`), fetcher)

  const loading = !data && !error

  let totalFees = null;
  if (data) {
    totalFees = (data.total || 0) / 1e6
    if (ALGORAND_LEDGER === 'MainNet') {
      totalFees += 170610.515650
    } else {
      totalFees += 2506
    }
  }

  return [
    totalFees,
    loading
  ]
}

export function useFeesData({ from = FIRST_DATE_TS, to = NOW_TS } = {}) {
  const { data, error } = useSWR(getApiUrl(`/daily_fees/?timestamp__gte=${from}&timestamp__lt=${to}`), fetcher)

  const loading = !data && !error

  const ret = useMemo(() => {
    if (!data) {
      return null
    }

    let cumulative = 0
    const cumulativeByTs = {}
    return data.map(item => {
      const timestamp = item.timestamp
      const all = item.daily_total / 1e6

      let movingAverageAll
      const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      cumulative += all
      cumulativeByTs[timestamp] = cumulative
      return {
        timestamp,
        all,
        cumulative,
        movingAverageAll,
        margin: item.margin / 1e6,
        liquidation: item.liquidation / 1e6,
        swap: item.swap / 1e6,
        mint: item.mint / 1e6,
        burn: item.burn / 1e6,
      }
    })
  }, [data])

  return [ret, loading, error]
}

export function useAumPerformanceData({ from = FIRST_DATE_TS, to = NOW_TS, groupPeriod }) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod })
  const [flpData, flpLoading] = useFlpData({ from, to, groupPeriod })
  const [volumeData, volumeLoading] = useVolumeDataFromServer({ from, to, groupPeriod })

  const dailyCoef = 86400 / groupPeriod

  const data = useMemo(() => {
    if (!feesData || !flpData || !volumeData) {
      return null
    }

    const ret = feesData.map((feeItem, i) => {
      const flpItem = flpData[i]
      const volumeItem = volumeData[i]
      let apr = (feeItem?.all && flpItem?.aum) ? feeItem.all /  flpItem.aum * 100 * 365 * dailyCoef : null
      if (apr > 10000) {
        apr = null
      }
      let usage = (volumeItem?.all && flpItem?.aum) ? volumeItem.all / flpItem.aum * 100 * dailyCoef : null
      if (usage > 10000) {
        usage = null
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage
      }
    })
    const averageApr = ret.reduce((memo, item) => item.apr + memo, 0) / ret.length
    ret.forEach(item => item.averageApr = averageApr)
    const averageUsage = ret.reduce((memo, item) => item.usage + memo, 0) / ret.length
    ret.forEach(item => item.averageUsage = averageUsage)
    return ret
  }, [dailyCoef, feesData, flpData, volumeData])

  return [data, feesLoading || flpLoading || volumeLoading]
}

export function useFlpData({ from = FIRST_DATE_TS, to = NOW_TS } = {}) {
  const { data, error } = useSWR(getApiUrl(`/flp_stats/?timestamp__gte=${from}&timestamp__lt=${to}&period_type=daily`), fetcher)

  const loading = !data && !error

  const ret = useMemo(() => {
    if (!data) {
      return null
    }

    let prevFlpSupply
    let prevAum

    return data.map(item => {
      const timestamp = parseInt(item.timestamp / 86400) * 86400
      const aum = item.tvl / 1e6
      const flpSupply = item.flp_supply / item.flp_price_factor / 1e6

      const flpPrice = aum / flpSupply;

      let flpSupplyChange = prevFlpSupply ? (flpSupply - prevFlpSupply) / prevFlpSupply * 100 : 0
      if (flpSupplyChange > 1000) {
        flpSupplyChange = 0
      }

      let aumChange = prevAum ? (aum - prevAum) / prevAum * 100 : 0
      if (aumChange > 1000) {
        aumChange = 0;
      }
      
      prevFlpSupply = flpSupply
      prevAum = aum

      return {
        timestamp,
        aum,
        flpSupply,
        flpPrice,
        flpSupplyChange,
        aumChange,
        totalFees: item.total_fees,
        flpFeePrice: item.flp_fee_price * item.flp_price_factor / 1e6,
      }
    })
  }, [data])

  return [ret, loading, error]
}

export function useFlpPerformanceData(flpData, feesData, { from = FIRST_DATE_TS } = {}) {
  if (from < FIRST_DATE_TS) {
    from = FIRST_DATE_TS
  }
  const [algoPrices] = useCoingeckoPrices('ALGO', { from })
  const [xsolPrices] = useCoingeckoPrices('XSOL', { from })

  const flpPerformanceChartData = useMemo(() => {
    if (!algoPrices || !xsolPrices || !flpData || !feesData) {
      return null
    }

    const flpDataById = flpData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    let ALGO_WEIGHT = 0.30
    let XSOL_WEIGHT = 0.15

    const STABLE_WEIGHT = 1 - ALGO_WEIGHT - XSOL_WEIGHT
    const FLP_START_PRICE = flpDataById[algoPrices[0].timestamp]?.flpPrice || 1.0

    const algoFirstPrice = algoPrices[0]?.value
    const xsolFirstPrice = xsolPrices[0]?.value

    let indexAlgoCount = FLP_START_PRICE * ALGO_WEIGHT / algoFirstPrice
    let indexXSolCount = FLP_START_PRICE * XSOL_WEIGHT / xsolFirstPrice
    let indexStableCount = FLP_START_PRICE * STABLE_WEIGHT

    const lpAlgoCount = FLP_START_PRICE * 0.45 / algoFirstPrice
    const lpXSolCount = FLP_START_PRICE * 0.45 / xsolFirstPrice

    const ret = []
    let cumulativeFeesPerFlp = 0
    let lastFlpItem
    let lastFeesItem

    let prevXSolPrice = 32
    for (let i = 0; i < algoPrices.length; i++) {
      const algoPrice = algoPrices[i].value
      const xsolPrice = xsolPrices[i]?.value || prevXSolPrice
      prevXSolPrice = xsolPrice

      const timestampGroup = parseInt(algoPrices[i].timestamp / 86400) * 86400
      const flpItem = flpDataById[timestampGroup] || lastFlpItem
      lastFlpItem = flpItem

      const flpPrice = flpItem?.flpPrice
      const flpSupply = flpItem?.flpSupply
      
      const feesItem = feesDataById[timestampGroup] || lastFeesItem
      lastFeesItem = feesItem

      const dailyFees = feesItem?.all

      const syntheticPrice = (
        indexAlgoCount * algoPrice
        + indexXSolCount * xsolPrice
        + indexStableCount
      )

      // rebalance each day. can rebalance each X days
      if (i % 1 === 0) {
        indexAlgoCount = syntheticPrice * ALGO_WEIGHT / algoPrice
        indexXSolCount = syntheticPrice * XSOL_WEIGHT / xsolPrice
        indexStableCount = syntheticPrice * STABLE_WEIGHT
      }

      const lpAlgoPrice = (lpAlgoCount * algoPrice + FLP_START_PRICE / 2) * (1 + getImpermanentLoss(algoPrice / algoFirstPrice))
      const lpXSolPrice = (lpXSolCount * xsolPrice + FLP_START_PRICE / 2) * (1 + getImpermanentLoss(xsolPrice / xsolFirstPrice))

      if (dailyFees && flpSupply) {
        // const INCREASED_FLP_REWARDS_TIMESTAMP = 1635714000
        // const FLP_REWARDS_SHARE = timestampGroup >= INCREASED_FLP_REWARDS_TIMESTAMP ? 0.7 : 0.5
        const FLP_REWARDS_SHARE = 1
        const collectedFeesPerFlp = dailyFees / flpSupply * FLP_REWARDS_SHARE
        cumulativeFeesPerFlp += collectedFeesPerFlp
      }

      let flpPlusFees = flpPrice
      if (flpPrice && flpSupply && cumulativeFeesPerFlp) {
        flpPlusFees = flpPrice + cumulativeFeesPerFlp
      }

      // let flpApr
      // let flpPlusDistributedUsd
      // let flpPlusDistributedAlgo
      // if (flpItem) {
      //   if (flpItem.cumulativeDistributedUsdPerFlp) {
      //     flpPlusDistributedUsd = flpPrice + flpItem.cumulativeDistributedUsdPerFlp
      //     // flpApr = flpItem.distributedUsdPerFlp / flpPrice * 365 * 100 // incorrect?
      //   }
      //   if (flpItem.cumulativeDistributedAlgoPerFlp) {
      //     flpPlusDistributedAlgo = flpPrice + flpItem.cumulativeDistributedAlgoPerFlp * xsolPrice
      //   }
      // }

      ret.push({
        timestamp: algoPrices[i].timestamp,
        syntheticPrice,
        lpAlgoPrice,
        lpXSolPrice,
        flpPrice,
        algoPrice,
        xsolPrice,
        flpPlusFees,
        flpPlusDistributedUsd: null,
        flpPlusDistributedAlgo: null,

        indexAlgoCount,
        indexXSolCount,
        indexStableCount,

        ALGO_WEIGHT,
        XSOL_WEIGHT,
        STABLE_WEIGHT,

        performanceLpAlgo: (flpPrice / lpAlgoPrice * 100).toFixed(2),
        performanceLpAlgoCollectedFees: (flpPlusFees / lpAlgoPrice * 100).toFixed(2),
        // performanceLpAlgoDistributedUsd: (flpPlusDistributedUsd / lpAlgoPrice * 100).toFixed(2),
        // performanceLpAlgoDistributedAlgo: (flpPlusDistributedAlgo / lpAlgoPrice * 100).toFixed(2),
        performanceLpAlgoDistributedUsd: null,
        performanceLpAlgoDistributedAlgo: null,

        performanceLpXSolCollectedFees: (flpPlusFees / lpXSolPrice * 100).toFixed(2),

        performanceSynthetic: (flpPrice / syntheticPrice * 100).toFixed(2),
        performanceSyntheticCollectedFees: (flpPlusFees / syntheticPrice * 100).toFixed(2),
        // performanceSyntheticDistributedUsd: (flpPlusDistributedUsd / syntheticPrice * 100).toFixed(2),
        // performanceSyntheticDistributedAlgo: (flpPlusDistributedAlgo / syntheticPrice * 100).toFixed(2),
        performanceSyntheticDistributedUsd: null,
        performanceSyntheticDistributedAlgo: null,

        flpApr: null
      })
    }

    return ret
  }, [algoPrices, xsolPrices, flpData, feesData])

  return [flpPerformanceChartData]
}

export function useAssetStats({ 
  from = FIRST_DATE_TS,
  to = NOW_TS,
} = {}) {

  const { data: dbData, error } = useSWR(getApiUrl(`/pool_composition/?timestamp__gte=${from}&timestamp__lt=${to}&type=daily`), fetcher)

  const loading = !dbData && !error

  const data = useMemo(() => {
    if (loading || !dbData) {
      return null
    }

    const poolAmountUsdRecords = []

    dbData.forEach(dbItem => {
      const item = {
        timestamp: dbItem.timestamp,
        all: (dbItem.algo_amount_usd + dbItem.usdc_amount + dbItem.usdt_amount + dbItem.xsol_amount_usd
          + dbItem.gobtc_amount_usd + dbItem.goeth_amount_usd + dbItem.galgo_amount_usd) / 1e6,
        ALGO: dbItem.algo_amount_usd / 1e6,
        USDC: dbItem.usdc_amount / 1e6,
        USDT: dbItem.usdt_amount / 1e6,
        xSOL: dbItem.xsol_amount_usd / 1e6,
        goBTC: dbItem.gobtc_amount_usd / 1e6,
        goETH: dbItem.goeth_amount_usd / 1e6,
        gALGO: dbItem.galgo_amount_usd / 1e6,
      }

      poolAmountUsdRecords.push(item)
    })

    return {
      poolAmountUsd: poolAmountUsdRecords,
      assetUnitNames: ['ALGO', 'USDC', 'USDT', 'xSOL', 'goBTC', 'goETH', 'gALGO'],
    };
  }, [dbData, loading])

  return [data, loading, error]
}

// export function useReferralsData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "arbitrum" } = {}) {
//   const query = `{
//     globalStats(
//       first: 1000
//       orderBy: timestamp
//       orderDirection: desc
//       where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
//       subgraphError: allow
//     ) {
//       volume
//       volumeCumulative
//       totalRebateUsd
//       totalRebateUsdCumulative
//       discountUsd
//       discountUsdCumulative
//       referrersCount
//       referrersCountCumulative
//       referralCodesCount
//       referralCodesCountCumulative
//       referralsCount
//       referralsCountCumulative
//       timestamp
//     }
//   }`
//   const subgraph = chainName === "arbitrum" ? "gdev8317/gmx-arbitrum-referrals-staging" : "gdev8317/gmx-avalanche-referrals-staging"
//   const [graphData, loading, error] = useGraph(query, { subgraph })

//   const data = graphData ? sortBy(graphData.globalStats, 'timestamp').map(item => {
//     const totalRebateUsd = item.totalRebateUsd / 1e30
//     const discountUsd = item.discountUsd / 1e30
//     return {
//       ...item,
//       volume: item.volume / 1e30,
//       volumeCumulative: item.volumeCumulative / 1e30,
//       totalRebateUsd,
//       totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
//       discountUsd,
//       referrerRebateUsd: totalRebateUsd - discountUsd,
//       discountUsdCumulative: item.discountUsdCumulative / 1e30,
//       referralCodesCount: parseInt(item.referralCodesCount),
//       referralCodesCountCumulative: parseInt(item.referralCodesCountCumulative),
//       referrersCount: parseInt(item.referrersCount),
//       referrersCountCumulative: parseInt(item.referrersCountCumulative),
//       referralsCount: parseInt(item.referralsCount),
//       referralsCountCumulative: parseInt(item.referralsCountCumulative),
//     }
//   }) : null

//   return [data, loading, error]
// }
