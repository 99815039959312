export const ALGORAND_LEDGER = 'MainNet';

export const API_URL = ALGORAND_LEDGER === 'MainNet'
  ? 'https://node.algoexplorerapi.io'
  : 'https://node.testnet.algoexplorerapi.io';

export const INDEXER_URL = ALGORAND_LEDGER === 'MainNet'
  ? 'https://algoindexer.algoexplorerapi.io'
  : 'https://algoindexer.testnet.algoexplorerapi.io';

export const EXPLORER_URL = ALGORAND_LEDGER === 'MainNet'
  ? 'https://algoexplorer.io'
  : 'https://testnet.algoexplorer.io';