import React from 'react'
import { useAssetStats } from '../../dataProvider';
import { 
    COINCOLORS, convertToPercents,
 } from '../../helpers';
import GenericChart from '../GenericChart';

const convertToPercentsHandler = (data) =>
  convertToPercents(data, {totalKey: 'all', ignoreKeys: []})


function getAssetColor(index) {
    return COINCOLORS[index % COINCOLORS.length];
}

export default function PoolAmountChart({
    from,
    to,
    syncId,
}) {
    const [assetStatsData, assetStatsLoading] = useAssetStats({from, to});

    const chartLegendItems = (assetStatsData && assetStatsData.assetUnitNames)
        ? assetStatsData.assetUnitNames.map((unitName, i) => ({
            key: unitName,
            color: getAssetColor(i),
            fillOpacity: 0.5
        }))
        : [];

    return (
        <GenericChart
            syncId={syncId}
            loading={assetStatsLoading}
            title="Pool Composition"
            data={assetStatsData ? assetStatsData.poolAmountUsd : null}
            controls={{
                convertToPercents: convertToPercentsHandler,
            }}
            yaxisDataKey="all"
            items={chartLegendItems}
            type="Area"
        />
    )
}