import React, { useState, useEffect } from 'react';
import { Route, Routes, NavLink, BrowserRouter as Router } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import cx from "classnames";
import Algorand from './views/Algorand';
// import Trading from './views/Trading';
import './App.css';
import logoIcon from './img/logo-dark.png';
import darkLogoIcon from './img/logo-dark.png';
import lightLogoIcon from './img/logo-light.png';
import { FaSun, FaMoon/*, FaTimes*/ } from "react-icons/fa";
import { ALGORAND_LEDGER } from './config';
// import { FiX } from "react-icons/fi";
// import { RiMenuLine } from 'react-icons/ri';

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small &&
        <div className="App-header-links-header">
          {/* <div className="App-header-menu-icon-block" onClick={() => clickCloseIcon()}>
            <FiX className="App-header-menu-icon" />
          </div> */}
          <NavLink
            exact="true"
            className={({isActive}) => isActive ? "App-header-link-main active" : "App-header-link-main"}
            to="/"
          >
            <img src={logoIcon} alt="fxdx Logo" />
          </NavLink>
        </div>
      }
    </div>
  )
}

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 }
  }

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }

  useEffect(() => {
    const savedMode = window.localStorage.getItem('mode');
    const targetMode = savedMode === 'light' ? 'light' : 'dark';
    document.querySelector('body').style.backgroundColor = targetMode === 'dark' ? '#101124' : '#f6f9ff';
    setMode(targetMode);
  }, [])

  const switchMode = () => {
    const targetMode = mode === 'dark' ? 'light' : 'dark';
    window.localStorage.setItem('mode', targetMode);
    document.querySelector('body').style.backgroundColor = targetMode === 'dark' ? '#101124' : '#f6f9ff';
    setMode(targetMode)
  }

  const appUrl = ALGORAND_LEDGER === "MainNet" ? "https://mainnet.fxdx.exchange" : "https://algo.fxdx.exchange";

  return (
    <Router>
      {
        mode && <div className={cx("App", mode)}>
          {isDrawerVisible &&
            <AnimatePresence>
              {isDrawerVisible &&
                <motion.div className="App-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                >
                </motion.div>
              }
            </AnimatePresence>
          }
          <div className="nav">
            <div className="nav-left">
              {/* <div className="App-header-menu-icon-block" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                {!isDrawerVisible && <RiMenuLine className="App-header-menu-icon" />}
                {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
              </div> */}
              <a href={appUrl} target="_blank" className="nav-logo" rel="noreferrer">
                <img src={mode === 'dark' ? darkLogoIcon : lightLogoIcon} alt="fxdx" />
              </a>
            </div>
            <div className="nav-right">
              <a href={appUrl} target="_blank" className="nav-link" rel="noreferrer">APP</a>
              <a href="https://fxdxdex.github.io/howto/" target="_blank" className="nav-link" rel="noreferrer">DOCS</a>
              <div className='modeselect' onClick={() => switchMode()}>
                {mode === 'dark' ? <FaSun /> : <FaMoon />}
              </div>
            </div>
          </div>
          <AnimatePresence>
            {isDrawerVisible &&
              <motion.div
                onClick={() => setIsDrawerVisible(false)}
                className="App-header-links-container App-header-drawer"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={slideVariants}
                transition={{ duration: 0.2 }}
              >
                <AppHeaderLinks mode={mode} small clickCloseIcon={() => setIsDrawerVisible(false)} />
              </motion.div>
            }
          </AnimatePresence>
          <div className="content">
            <Routes>
              <Route path="/" element={<Algorand mode={mode} />} />
              {/* <Route path="/trading" element={<Trading />}  /> */}
            </Routes>
          </div>
        </div>
      }
    </Router>
  )
};

export default App;
