import React, { useState, useMemo, useCallback } from 'react';
// import moment from 'moment'
import { RiLoader5Fill } from 'react-icons/ri'
import cx from "classnames";

import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  formatNumber,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
  convertToPercents
} from '../helpers'

import {
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Cell
} from 'recharts';

import ChartWrapper from '../components/ChartWrapper'
import VolumeChart from '../components/VolumeChart'
import FeesChart from '../components/FeesChart'
import GenericChart from '../components/GenericChart'
import DateRangeSelect from '../components/DateRangeSelect'

import {
  useTotalVolumeFromServer,
  useVolumeDataFromServer,
  useFeesData,
  useTotalFees,
  useFlpData,
  useAumPerformanceData,
  useFlpPerformanceData,
  useTradersData,
//   useSwapSources,
  // useFundingRateData,
  useUsersData,
//   useLastSubgraphBlock,
  useLastRound
} from '../dataProvider'
import PoolAmountChart from '../components/PoolAmountChart';
import TradersProfitLossChart from '../components/TradersProfitLossChart';
import { startOfDay } from 'date-fns';
import { EXPLORER_URL } from '../config';

const NOW = Math.floor(Date.now() / 1000)

const dateRangeOptions = [{
  label: "Last Month",
  id: 1
}, {
  label: "Last 2 Months",
  id: 2,
  // isDefault: true
}, {
  label: "Last 3 Months",
  id: 3,
}, {
  label: "All time",
  id: 4
}]

const timezoneOffset = - new Date().getTimezoneOffset() * 60;

function Algorand(props) {
  const DEFAULT_GROUP_PERIOD = 86400
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD)

  // const [dataRange, setDataRange] = useState({
  //   fromValue: moment().subtract(2, 'month').toDate(),
  //   toValue: null
  // })

  const [dataRange, setDataRange] = useState({
    fromValue: new Date(2022, 6, 8),
    toValue: new Date(2023, 0, 3)
  })

  const { mode } = props

  const from = dataRange.fromValue ? Math.floor(+startOfDay(new Date(dataRange.fromValue)) / 1000)  + timezoneOffset : undefined
  const to = dataRange.toValue ? Math.floor(+new Date(dataRange.toValue) / 1000) : NOW

  const params = { from, to, groupPeriod }

  // const [fundingRateData, fundingRateLoading] = useFundingRateData(params)

  const [volumeData, volumeLoading] = useVolumeDataFromServer(params)
  const [totalVolume] = useTotalVolumeFromServer()
  const totalVolumeDelta = useMemo(() => {
    if (!volumeData || !volumeData.length) {
      return null
    }
    return volumeData[volumeData.length - 1].all
  }, [volumeData])

  const [feesData, feesLoading] = useFeesData(params)
  const [totalFees] = useTotalFees()
  const totalFeesDelta = useMemo(() => {
    if (!feesData || !feesData.length) {
      return null
    }
    return feesData[feesData.length - 1].all
  }, [feesData])

  const [flpData, flpLoading] = useFlpData(params)
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!flpData) {
      return []
    }
    const total = flpData[flpData.length - 1]?.aum
    const delta = total - flpData[flpData.length - 2]?.aum
    return [total, delta]
  }, [flpData])

  const [aumPerformanceData, aumPerformanceLoading] = useAumPerformanceData(params)
  const [flpPerformanceData] = useFlpPerformanceData(flpData, feesData, params)

  const [tradersData, tradersLoading] = useTradersData(params)
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!tradersData) {
      return []
    }
    const total = tradersData.data[tradersData.data.length - 1]?.openInterest
    const delta = total - tradersData.data[tradersData.data.length - 2]?.openInterest
    return [total, delta]
  }, [tradersData])
  // const [swapSources, swapSourcesLoading] = useSwapSources(params)
  // const swapSourcesKeys = Object.keys((swapSources || []).reduce((memo, el) => {
  //   Object.keys(el).forEach(key => {
  //     if (key === 'all' || key === 'timestamp') return
  //     memo[key] = true
  //   })
  //   return memo
  // }, {}))

  const [usersData, usersLoading] = useUsersData(params)
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!usersData) {
      return [null, null]
    }
    const total = usersData[usersData.length - 1]?.uniqueCountCumulative
    const prevTotal = usersData[usersData.length - 2]?.uniqueCountCumulative
    const delta = total && prevTotal ? total - prevTotal : null
    return [
      total,
      delta
    ]
  }, [usersData])

  // const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock()
  const [lastRound] = useLastRound()

  // // const isObsolete = lastSubgraphBlock && lastRound && lastRound.timestamp - lastSubgraphBlock.timestamp > 3600
  const isObsolete = false

  // const [isExperiment, setIsExperiment] = useState(false)
  // useEffect(() => {
  //   setIsExperiment(window.localStorage.getItem('experiment'))
  // }, [setIsExperiment])

  const onDateRangeChange = useCallback((dates) => {
    const [start, end] = dates;
    
      setDataRange({
        fromValue: start,
        toValue: end
      })
  }, []);

  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          <h1>Analytics</h1>
          {/* {lastSubgraphBlock && lastRound &&
            <p className={cx('page-description', { warning: isObsolete })}>
              {isObsolete && "Data is obsolete. "}
              Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
              &nbsp;at block <a rel="noreferrer" target="_blank" href={`https://arbiscan.io/block/${lastSubgraphBlock.number}`}>{lastSubgraphBlock.number}</a>
            </p>
          }
          {
            lastSubgraphBlockError &&
            <p className="page-description warning">
              Subgraph data is temporarily unavailable.
            </p>
          } */}
          <p className={cx('page-description', { warning: isObsolete })}>
            {isObsolete && "Data is obsolete. "}
            Updated
            &nbsp;at block <a rel="noreferrer" target="_blank" href={`${EXPLORER_URL}/block/${lastRound}`}>{lastRound}</a>
          </p>
        </div>
        <div className="form">
          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.fromValue}
            endDate={dataRange.toValue}
            onChange={onDateRangeChange}
          />
        </div>
      </div>
      <div className="chart-grid">
        <div className="chart-cell stats">
          {totalVolume ? <>
            <div className="total-stat-label">Total Volume</div>
            <div className="total-stat-value">
              {formatNumber(totalVolume, { currency: true })}
              {totalVolumeDelta &&
                <span className="total-stat-delta plus" title="Change since previous day">+{formatNumber(totalVolumeDelta, { currency: true, compact: true })}</span>
              }
            </div>
          </> : <RiLoader5Fill size="3em" className="loader" />}
        </div>
        <div className="chart-cell stats">
          {!isNaN(totalFees) && !isNaN(totalFeesDelta) ? <>
            <div className="total-stat-label">Total Fees</div>
            <div className="total-stat-value">
              {formatNumber(totalFees, { currency: true })}
              <span className="total-stat-delta plus" title="Change since previous day">+{formatNumber(totalFeesDelta, { currency: true, compact: true })}</span>
            </div>
          </> : (feesLoading ? <RiLoader5Fill size="3em" className="loader" /> : null)}
        </div>
        <div className="chart-cell stats">
          {totalAum ? <>
            <div className="total-stat-label">FLP Pool</div>
            <div className="total-stat-value">
              {formatNumber(totalAum, { currency: true })}
              <span className={cx("total-stat-delta", (totalAumDelta > 0 ? 'plus' : 'minus'))} title="Change since previous day">{totalAumDelta > 0 ? '+' : ''}{formatNumber(totalAumDelta, { currency: true, compact: true })}</span>
            </div>
          </> : (flpLoading ? <RiLoader5Fill size="3em" className="loader" /> : null)}
        </div>
        <div className="chart-cell stats">
          {totalUsers ? <>
            <div className="total-stat-label">Total Users</div>
            <div className="total-stat-value">
              {formatNumber(totalUsers, {isInt: true})}
              <span className="total-stat-delta plus" title="Change since previous day">+{formatNumber(totalUsersDelta, {isInt: true})}</span>
            </div>
          </> : (usersLoading ? <RiLoader5Fill size="3em" className="loader" /> : null)}
        </div>
        <div className="chart-cell stats">
          {openInterest ? <>
            <div className="total-stat-label">Open Interest</div>
            <div className="total-stat-value">
              {formatNumber(openInterest, { currency: true })}
              <span className={cx("total-stat-delta", (openInterestDelta > 0 ? 'plus' : 'minus'))} title="Change since previous day">
                {openInterestDelta > 0 ? '+' : ''}{formatNumber(openInterestDelta, { currency: true, compact: true })}
              </span>
            </div>
          </> : (tradersLoading ? <RiLoader5Fill size="3em" className="loader" /> : null)}
        </div>
        <div className="chart-cell">
          <VolumeChart
            data={volumeData}
            loading={volumeLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <FeesChart
            data={feesData}
            loading={feesLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="TVL & Flp Supply"
            loading={flpLoading}
            data={flpData}
            csvFields={[{ key: 'aum', name: 'TVL' }, { key: 'flpSupply' }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpData} syncId="syncFlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis dataKey="aum" tickFormatter={yaxisFormatter} width={YAXIS_WIDTH} />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: 'left' }}
                />
                <Legend />
                <Line isAnimationActive={false} type="monotone" strokeWidth={2} unit="$" dot={false} dataKey="aum" stackId="a" name="TVL" stroke={COLORS[0]} />
                <Line isAnimationActive={false} type="monotone" strokeWidth={2} dot={false} dataKey="flpSupply" stackId="a" name="Flp Supply" stroke={COLORS[1]} />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <PoolAmountChart 
            from={from}
            to={to}
            syncId="syncFlp"
          />
        </div>
        {/* <div className="chart-cell">
          <ChartWrapper
            title="Flp Performance"
            loading={flpLoading}
            data={flpPerformanceData}
            csvFields={[
              {key: 'syntheticPrice'},
              {key: 'flpPrice'},
              {key: 'flpPlusFees'},
              {key: 'lpAlgoPrice'},
              {key: 'lpXSolPrice'},
              {key: 'performanceSyntheticCollectedFees'},
              {key: 'indexAlgoCount'},
              {key: 'indexXSolCount'},
              {key: 'indexStableCount'},
              {key: 'ALGO_WEIGHT'},
              {key: 'XSOL_WEIGHT'},
              {key: 'STABLE_WEIGHT'},
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpPerformanceData} syncId="syncFlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis dataKey="performanceSyntheticCollectedFees" domain={[80, 130]} unit="%" tickFormatter={yaxisFormatterNumber} width={YAXIS_WIDTH} />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: 'left' }}
                />
                <Legend />
                <Line dot={false} isAnimationActive={false} type="monotone" unit="%" dataKey="performanceLpAlgoCollectedFees" name="% LP ALGO-USDC (w/ fees)" stroke={COLORS[2]} />
                <Line dot={false} isAnimationActive={false} type="monotone" unit="%" dataKey="performanceLpXSolCollectedFees" name="% LP xSOL-USDC (w/ fees)" stroke={COLORS[4]} />
                <Line dot={false} isAnimationActive={false} type="monotone" unit="%" dataKey="performanceSyntheticCollectedFees" name="% Index (w/ fees)" stroke={COLORS[0]} />
              </LineChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>
                <span style={{color: COLORS[0]}}>% of Index (with fees)</span> is Flp with fees / Index Price * 100. Index is a basket of 30% ALGO, 15% xSOL, 55% USDC rebalanced once&nbsp;a&nbsp;day
                <br/>
                <span style={{color: COLORS[4]}}>% of LP ALGO-USDC (with fees)</span> is Flp Price with fees / LP ALGO-USDC * 100<br/>
              </p>
            </div>
          </ChartWrapper>
        </div> */}
        <div className="chart-cell">
          <ChartWrapper
            title="Flp Price Comparison"
            loading={flpLoading}
            data={flpPerformanceData}
            csvFields={[
              {key: 'syntheticPrice'},
              {key: 'flpPrice'},
              {key: 'flpPlusFees'},
              {key: 'lpAlgoPrice'},
              {key: 'lpXSolPrice'},
              {key: 'performanceSyntheticCollectedFees'},
              {key: 'indexAlgoCount'},
              {key: 'indexXSolCount'},
              {key: 'indexStableCount'},
              {key: 'ALGO_WEIGHT'},
              {key: 'XSOL_WEIGHT'},
              {key: 'STABLE_WEIGHT'},
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpPerformanceData} syncId="syncFlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis dataKey="flpPrice" domain={[0.5, 1.6]} tickFormatter={yaxisFormatterNumber} width={YAXIS_WIDTH} />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: 'left' }}
                />
                <Legend />
                {/* <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="syntheticPrice" name="Index Price" stroke={COLORS[2]} /> */}
                <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="flpPrice" name="Flp Price" stroke={COLORS[1]} />
                <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={2} dot={false} dataKey="flpPlusFees" name="Flp w/ fees" stroke={COLORS[3]} />
                {/* <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="lpAlgoPrice" name="LP ALGO-USDC" stroke={COLORS[2]} />
                <Line isAnimationActive={false} type="monotone" unit="$" strokeWidth={1} dot={false} dataKey="lpXSolPrice" name="LP xSOL-USDC" stroke={COLORS[4]} /> */}
              </LineChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>
                <span style={{color: COLORS[3]}}>Flp with fees</span> is based on FLP share of fees received and excluding esFXDX rewards<br/>
                {/* <span style={{color: COLORS[2]}}>Index Price</span> is a basket of 30% ALGO, 15% xSOL, 55% USDC rebalanced once&nbsp;a&nbsp;day */}
              </p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={tradersLoading}
            title="Open Interest"
            data={tradersData?.data.map(item => ({ all: item.openInterest, ...item }))}
            controls={{
              convertToPercents: convertToPercents
            }}
            yaxisDataKey="all"
            items={[{ key: 'shortOpenInterest', name: 'Short', color: "#f93333" }, { key: 'longOpenInterest', name: 'Long', color: '#22c761' }]}
            type="Bar"
          />
        </div>
        {/* {isExperiment && <div className="chart-cell experiment">
          <ChartWrapper title="Performance vs. Index" loading={flpLoading}>
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpPerformanceData} syncId="syncFlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis dataKey="performanceSyntheticCollectedFees" domain={[80, 120]} unit="%" tickFormatter={yaxisFormatterNumber} width={YAXIS_WIDTH} />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: 'left' }}
                />
                <Legend />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceSyntheticCollectedFees" name="Collected Fees" stroke={COLORS[0]} />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceSyntheticDistributedUsd" name="Distributed Usd" stroke={COLORS[1]} />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceSyntheticDistributedEth" name="Distributed Eth" stroke={COLORS[2]} />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceSynthetic" name="No Fees" stroke={COLORS[3]} />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>}
        {isExperiment && <div className="chart-cell experiment">
          <ChartWrapper title="Performance vs. ETH LP" loading={flpLoading}>
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={flpPerformanceData} syncId="syncFlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis dataKey="performanceLpEthCollectedFees" domain={[80, 120]} unit="%" tickFormatter={yaxisFormatterNumber} width={YAXIS_WIDTH} />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: 'left' }}
                />
                <Legend />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceLpEthCollectedFees" name="Collected Fees" stroke={COLORS[0]} />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceLpEthDistributedUsd" name="Distributed Usd" stroke={COLORS[1]} />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceLpEthDistributedEth" name="Distributed Eth" stroke={COLORS[2]} />
                <Line isAnimationActive={false} dot={false} type="monotone" unit="%" strokeWidth={2} dataKey="performanceLpEth" name="No Fees" stroke={COLORS[3]} />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>} */}
        <div className="chart-cell">
          <ChartWrapper
            title="Traders Net PnL"
            loading={tradersLoading}
            data={tradersData?.data}
            csvFields={[{ key: 'pnl', name: 'Net PnL' }, { key: 'pnlCumulative', name: 'Cumulative PnL' }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={tradersData?.data} syncId="tradersId">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                <YAxis
                  domain={[-tradersData?.stats.maxAbsCumulativePnl * 1.05, tradersData?.stats.maxAbsCumulativePnl * 1.05]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  tick={{ fill: COLORS[4] }}
                />
                <YAxis domain={[-tradersData?.stats.maxAbsPnl * 1.05, tradersData?.stats.maxAbsPnl * 1.05]} tickFormatter={yaxisFormatter} width={YAXIS_WIDTH} />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: 'left' }}
                />
                <Legend />
                <Bar type="monotone" fill={mode === "dark" ? "#FFFFFF" : "#000000"} dot={false} dataKey="pnl" name="Net PnL">
                  {(tradersData?.data || []).map((item, i) => {
                    return <Cell key={`cell-${i}`} fill={item.pnl > 0 ? '#22c761' : '#f93333'} />
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="currentPnlCumulative"
                  name="Cumulative PnL"
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div className="chart-description">
              <p>Considers settled (closed) positions</p>
              <p>Fees are not factored into PnL</p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <TradersProfitLossChart 
            syncId="tradersId"
            loading={tradersLoading}
            tradersData={tradersData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
          />
        </div>
        {/* <div className="chart-cell">
           <GenericChart
              loading={fundingRateLoading}
              title="Borrowing Rate Annualized"
              data={fundingRateData}
              yaxisDataKey="ETH"
              yaxisTickFormatter={yaxisFormatterPercent}
              tooltipFormatter={tooltipFormatterPercent}
              items={[{ key: 'ETH' }, { key: 'BTC' }, { key: 'UNI' }, { key: 'LINK' }, { key: 'USDC' }, { key: 'USDT' }, { key: 'MIM' }, { key: 'FRAX', color: mode === "dark" ? "#FFF" : "#000" }, { key: 'DAI' }]}
              type="Line"
              yaxisDomain={[0, 90 / ~87% is a maximum yearly borrow rate /]}
              isCoinChart={true}
            />
        </div> */}
        <div className="chart-cell">
           <GenericChart
              syncId="syncFlp"
              loading={aumPerformanceLoading}
              title="TVL Performance Annualized"
              data={aumPerformanceData}
              yaxisDataKey="apr"
              yaxisTickFormatter={yaxisFormatterPercent}
              tooltipFormatter={tooltipFormatterPercent}
              items={[{ key: 'apr', name: 'APR', color: COLORS[0] }]}
              description="Formula = Daily Fees / FLP Pool * 365 days * 100"
              type="Composed"
            />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncFlp"
            loading={aumPerformanceLoading}
            title="TVL Daily Usage"
            data={aumPerformanceData}
            yaxisDataKey="usage"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: 'usage', name: 'Daily Usage', color: COLORS[4] }]}
            description="Formula = Daily Volume / FLP Pool * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncFlp"
            loading={usersLoading}
            title="Unique Users"
            data={usersData}
            truncateYThreshold={6500}
            yaxisDataKey="uniqueSum"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: 'uniqueSwapCount', name: 'Swaps' },
              { key: 'uniqueMarginCount', name: 'Margin trading' },
              { key: 'uniqueMintBurnCount', name: 'Mint & Burn FLP' }
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncFlp"
            loading={usersLoading}
            title="New Users"
            data={usersData?.map(item => ({ ...item, all: item.newCount }))}
            truncateYThreshold={6000}
            yaxisDataKey="newCount"
            rightYaxisDataKey="uniqueCountCumulative"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: 'newSwapCount', name: 'Swap' },
              { key: 'newMarginCount', name: 'Margin trading' },
              { key: 'newMintBurnCount', name: 'Mint & Burn' },
              { key: 'cumulativeNewUserCount', name: 'Cumulative', type: 'Line', yAxisId: 'right', strokeWidth: 2, color: COLORS[4] }
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
           <GenericChart
              syncId="syncFlp"
              loading={usersLoading}
              title="New vs. Existing Users"
              data={usersData?.map(item => ({ ...item, all: item.uniqueCount }))}
              truncateYThreshold={7000}
              yaxisDataKey="uniqueCount"
              rightYaxisDataKey="oldPercent"
              yaxisTickFormatter={yaxisFormatterNumber}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                { key: 'newCount', name: 'New'},
                { key: 'oldCount', name: 'Existing'},
                { key: 'oldPercent', name: 'Existing %', yAxisId: 'right', type: 'Line', strokeWidth: 2, color: COLORS[4], unit: '%' }
              ]}
              type="Composed"
            />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncFlp"
            loading={usersLoading}
            title="User Actions"
            data={(usersData || []).map(item => ({ ...item, all: item.actionCount }))}
            truncateYThreshold={25000}
            yaxisDataKey="actionCount"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[{ key: 'actionSwapCount', name: 'Swaps' }, { key: 'actionMarginCount', name: 'Margin trading' }, { key: 'actionMintBurnCount', name: 'Mint & Burn FLP' }]}
            type="Composed"
          />
        </div>
        {/* <div className="chart-cell">
          <GenericChart
            loading={swapSourcesLoading}
            title="Swap Sources"
            data={swapSources}
            items={swapSourcesKeys.map(key => ({ key }))}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Algorand;
